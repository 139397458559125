 

import "./LsItemBuyTrainVis.css";





export function LsItemBuyTrainVis( {dtBuy, reward, money, spendBon, name} )
{
  console.log('NAME: ', name);


    let p_prods = [];


    for (let k in name)
    {
      const e = name[k];

      p_prods.push(      <div className="tp-1"  key={k} >
                          <span>
                            <span className="tp-1-span">{e.name}</span>

                            { e.cnt > 1 ? (
                            <span className="tp-1-span2"> x{e.cnt}</span>

                            ) : null}
                          </span>
                        </div>  );
    }



    return (
    

<div className="ls-item-buy-train-vis">
  <div className="tx-date22">{dtBuy}</div>
  <div className="gg22">

      {p_prods}



    <div className="grp-spent">
      { money > 0 ? (
      <div className="t-money">{money} ₽</div>
      ) : null

  }
  { spendBon > 0 ? (
      <div className="t-bon-sp">-{spendBon} бонусов</div>
  ) : null

  }
    </div>


      { reward > 0 ? (

    <div className="t-reward">+{reward} бонусов</div>

      ) : null

      }


  </div>
</div>

    
    );
}
