 

import React from 'react';


import "./TraPlaClosed.css";



export function IcArrowUp()
{
    return (
<svg
  className="arar-up"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17 14L11.9992 9.42L7 14"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

    );
}

export function IcArrowClosed()
{
    return (
        <svg
            className="tra-pla-closed__aa"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            d="M7 10L12.0008 14.58L17 10"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    );
}



export function TraPlaClosed( {tit, solo, pair, grp} )
{
    const [isOpen, setIsOpen] = React.useState( false );

    function cb_clk()
    {
        console.log('CLICK !');
        setIsOpen( !isOpen );
    }


    const CLA = 'tra-pla-closed' + (isOpen ? ' ooo' : '');

    return (
    
    
        <div className={CLA} onClick={cb_clk} >
        <div className="tra-pla-closed__pl-name">{tit}</div>
            { isOpen ? <IcArrowUp /> : <IcArrowClosed /> }

            <div style={{position: 'absolute', top: '36px', height: 'calc(100% - 36px)', width: '100%'}}>

                <div style={gSTST}>
                    <div className="tr-name">Персональных</div>
                    <div className="tr-name">{solo}</div>
                </div>

                <div style={gSTST}>
                    <div className="tr-name">Парных</div>
                    <div className="tr-name">{pair}</div>
                </div>
 
                <div style={gSTST}>
                    <div className="tr-name">Групповых</div>
                    <div className="tr-name">{grp}</div>
                </div>

            </div>


        </div>

    
    );
}


const gSTST = {display: 'flex', justifyContent: 'space-between', paddingLeft: '16px', paddingRight: '16px'};
