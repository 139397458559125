 

import "./Title1.css";

export function Title1({tit} )
{
    return (

<div className="title-1">
  <div className="qqq">{tit}</div>
</div>


    );
}