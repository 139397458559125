 
 

import React from 'react';

import { motion, AnimatePresence } from "framer-motion"
import { IcArrowUp, IcArrowClosed } from "./TraPlaClosed";

import "./DropList.css"





function DrpItemXX( {tit, val, val2, onClick} )
{
    function cbcb(e)
    {
        onClick( val, val2 );
        e.preventDefault();
        e.stopPropagation();
    }

    return (

        <div className="drp-item-xx" onClick={cbcb} >
            <div className="drp-item-xx__tit">{tit}</div>
            <div className="drp-item-xx__sel">Выбрать</div>
        </div>


    );
}




export function DropList( {tit, src, onClick} )
{
    const [isOpen, setIsOpen] = React.useState( false );

    function cb_tog()
    {
        setIsOpen( !isOpen );
    }

    const NUM = src.length;

    if (NUM < 1) return <div></div>;

    const CLA = isOpen ? ' open' : '';


    const FIN_HEIGHT = 40 + (40 * NUM);

    const vrs = {
        clo: {  height: '40px'},
        opn:  { height: `${FIN_HEIGHT}px`},
    };


    const pp = [];

    for (let x = 0; x < src.length; x ++ )
    {
        const e = src[x];
        pp.push( <DrpItemXX key={e.v + '-' + x} tit={e.n} val={e.v}  val2={e.v2} onClick={onClick}/>  )
    }
    
    return (
        <motion.div  onClick={cb_tog} className={`drop-list ${CLA}`}
        transition={ {type: "spring", duration: 0.4, bounce: 0.5 } }  variants={vrs} initial="clo" animate={ isOpen ? 'opn' : 'clo' }    >
            <div className="drop-list__nn">{tit}</div>
            { isOpen ? <IcArrowUp /> :  <IcArrowClosed />  }

            <div style={{height: '36px' ,width: '100%'}}></div>

            {pp}


        </motion.div>

    );
}