 
 
import "./ScrNoAuth.css";





export function ScrNoAuth()
{
    return (
    
<div className="scr-no-auth">
  <svg
    className="scr-no-auth__alert" width="72" height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 38.7001V25.2434M36 48.6743V48.7926M53.0098 60H18.9902C14.3433 60 10.4218 56.9289 9.18796 52.7271C8.66128 50.9335 9.30842 49.0653 10.2983 47.4747L27.3081 16.8031C31.2933 10.399 40.7067 10.399 44.6919 16.8031L61.7017 47.4747C62.6916 49.0653 63.3387 50.9335 62.812 52.7271C61.5782 56.9288 57.6567 60 53.0098 60Z"
      stroke="#6A4CD7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  <div className="scr-no-auth__t-2">Ошибка</div>
  <div className="scr-no-auth__t-1">
    Fight Life тебя не знает!
  </div>

</div>

    
    );
}



/*
  <svg
    className="scr-no-auth__clo"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5104 6.4896L12.5 12.5M12.5 12.5L6.48959 18.5104M12.5 12.5L18.5104 18.5104M12.5 12.5L6.48959 6.48959"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
*/
