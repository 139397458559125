import "./Title2.css";

export const Title2 = ( {tit, desc} ) => {
  return (
<div className="title-2">
  <div className="title-2__t-1">{tit}</div>
  <div className="title-2__t-2">{desc}</div>
</div>

  );
};
