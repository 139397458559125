 
import { LayV, LayH, LaySpace, LayCenter } from './gui_com/layout.js';

import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";


//-----


export function SwitcherLR( {name, val, onChange, clr='#6a4cd7'}  )
{
    function cb_left()
    {
        onChange( name, -1 );
    }

    function cb_right()
    {
        onChange( name, 1 );
    }

    let p_left = <div style={{width: '1px', height: '1px'}}></div>;
    let p_right = <div style={{width: '1px', height: '1px'}}></div>;


    if (onChange)
    {
        p_left = <div onClick={cb_left} style={{textAlign: 'center', height: '100%'}}><FaAngleLeft size='19px' /></div>;
        p_right = <div onClick={cb_right} style={{textAlign: 'center', height: '100%'}}><FaAngleRight size='19px'  /></div>;
    
    }

    return ( <LayH cols='max-content 1fr max-content' sx={{color: clr, height: undefined}} >
                {p_left}
                <div style={{userSelect: 'none', fontFamily: 'Montserrat-Bold', fontSize: '18px', textAlign: 'center', height: '100%'}}>{val}</div>
                {p_right}
            </LayH>

    );
}
