 

import "./HeadBtnBack.css";


export function HeadBtnBack({onBack})
{
 
    if (!onBack) return null;

    return (

        <div onClick={onBack} style={{position: 'absolute', left: '10px', top: '24px'}}>

<svg
  className="head-btn-back"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M15 17L10 12L15 7"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

</div>

    );
}