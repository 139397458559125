import React from 'react';



//  background: linear-gradient(180deg, rgba(15, 15, 15, 0.00) 0%,rgba(15, 15, 15, 1.00) 100%); 

function DaFaderTopImp()
{
    return (<div style={{background: 'linear-gradient(0deg, #0F0F0F00 0%, #0F0F0FFF 100%)', width: '100%', top: 0, left: 0, position: 'absolute', height: '80px'}}>
            </div>);
}


function DaFaderBottomImp()
{
    return (<div style={{pointerEvents: 'none', background: 'linear-gradient(180deg, #0F0F0F00 0%, #0F0F0FFF 100%)', width: '100%', bottom: 0, left: 0, position: 'absolute', height: '80px'}}>
            </div>);
}


export const DaFaderTop        = React.memo( DaFaderTopImp );
export const DaFaderBottom     = React.memo( DaFaderBottomImp );
