 

 import React from 'react';

 import { ApiReq } from './api.js';

 import { __ActNameFinal } from './shit.js';

 import { LayV, LayH, LaySpace, LayCenter } from './gui_com/layout.js';
 
 import { SwitcherLR } from './switcher.js';
 
 import { LongButton } from './vis/LongButton.js';
 
 import * as DT from './dt.js';
 


//-----------------------------




function SingWeek( {dtMon, needMonth, listAvailDates} )
{
    let ar = [];

    for (let x = 0; x < 7; x ++ )
    {
        const mi = DT.DateMonthIndex( dtMon );


        if (mi === needMonth)
        {
            const s_day = DT.DateFmtDayNum( dtMon );

            const ii = parseInt( s_day );

            const isAvail = listAvailDates.indexOf( ii ) !== -1;

            

            let CLA = 'sch_calen_cell';

            if (!isAvail) CLA += ' dis';
            else CLA += ' avail';

            ar.push( <div key={x} className={CLA}>{s_day}</div> );
        }
        else
        {
            ar.push( <div key={x} className="sch_calen_cell_null"></div> );
        }

        dtMon = DT.DateAddDays( dtMon, 1 );
    }

    return (<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '34px'}}>
        {ar}
    </div>);
}

function WeekTitles()
{
    return (<div className="sch_calen_tits">

        <div>Пн</div>
        <div>Вт</div>
        <div>Ср</div>
        <div>Чт</div>
        <div>Пт</div>
        <div className="hh">Сб</div>
        <div className="hh">Вс</div>

    </div>);
}


function Weeks( {dt, listAvailDates} )
{
    let ar = [];

    const needMonth = DT.DateMonthIndex( dt );

    dt = DT.DateStartOfWeek( dt );
    dt = DT.DateSetHMS( dt, 0, 0, 0 ); 

    for (let x = 0; x < 6; x ++ )
    {
        ar.push( <SingWeek key={x} dtMon={dt} needMonth={needMonth} listAvailDates={listAvailDates} /> );

        dt = DT.DateAddDays( dt, 7 );

        if (DT.DateMonthIndex( dt ) !== needMonth ) break;
    }

    return (

        <div style={{display: 'flex', gap: '8px', width: '100%', flexDirection: 'column', paddingBottom: '30px'}}>
            {ar}
            <WeekTitles />
        </div>
    );
}


export function SubSchedCalen( {st, hkApp} )
{
    const DCT_ACT = st.sta.dctActs;

    const visSelectedAct = __ActNameFinal( DCT_ACT[ st.sta.curAct ] );


    React.useEffect( () => {

        console.log('AT CALEN.. ', st.sta);


            function __ok(e)
            {
                console.log('GOT CALEN REQ LOAD ! ', e);
                //setReady(true);
            }
    
            function __fail(e)
            {
    
            }
    
            ApiReq( 'calenReqLoad', {}, __ok, __fail );
    

    }, [] );


    let dt = new Date();
    

    const listAvailDates = [ 5, 6, 7, 10,11, 15, 16, 17, 20, 22, 23, 26,27, 29 ];

    return (

        <div style={{width: '100%', paddingLeft: '20px', paddingRight: '20px'}}>

<div style={{paddingTop: '30px', paddingBottom: '20px'}}>
                    <SwitcherLR clr='#FFFFFF' val={visSelectedAct} onChange={null} />
                    </div>

                    <div style={{paddingTop: '20px', paddingBottom: '30px'}}>
                    <SwitcherLR  val='Сентябрь 2024' onChange={null} />
                    </div>

            <LayH cols='1fr 320px 1fr' >

                <LaySpace />

                <div>

                    <Weeks dt={dt} listAvailDates={listAvailDates} />
                    <LongButton tit='Записаться' />
                </div>

                <LaySpace />

            </LayH>

        </div>
    );
}
