 

import "./TitKids.css";




export function TitleKids1( {tit, nam, onClose} )
{
    return (
    
<div className="title-kids-1">
  <div className="title-kids-1__tit">{tit}</div>
  <div className="title-kids-1__sub">{nam}</div>
  <div className="title-kids-1__clo" onClick={onClose}>Закрыть</div>
</div>

    
    );
}


export function TitleKids2( {tit, nam, onClose} )
{
    return (
    
<div className="title-kids-2">
  <div className="title-kids-2__tit">{tit}</div>
  <div className="title-kids-2__sub">{nam}</div>
  <div className="title-kids-2__clo" onClick={onClose}>Закрыть</div>
</div>

    
    );
}
