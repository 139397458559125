import React from 'react';

import { TraPlaClosed } from "./TraPlaClosed.js"

import { KidsPlashkaOpen } from './KidsPlashkaOpen.js';

import "./StartPageJuice.css";






export function StartPageJuice( {kids, onClickKid, dudeName, levelStr, extraBonus, bonusAmt, numClosed, next1, next2, cnts, cntsOpen} )
{
  //console.log('START PAGE ', kids, onClickKid);

    const [numClicks, setNumClicks] = React.useState( 0 );

    function cb_click()
    {
        //console.log('TORK !');
        
        setNumClicks( numClicks + 1);
    }


    if (numClicks > 20) dudeName = 'Иванов Иван Иванович';

    return (

<div className="start-page-juice"  >
  <div className="tx-dude-name"  style={{userSelect: 'none', pointerEvents: 'all'}} onClick={cb_click} >{dudeName}</div>


  { levelStr !== null ? (
  <div className="loy-shit">
    <div className="frm">
      <div className="mmm">Уровень лояльности</div>
      <div className="t-desc">
        <span>
          <span className="t-desc-span2">+{extraBonus}</span>
          <span className="t-desc-span"> бонусов к каждой тренировке</span>
        </span>
      </div>
      <div className="lev">
        <div className="tx">{levelStr}</div>
      </div>
    </div>
  </div> ) : null }


    <KidsPlashkaOpen src={kids} onClickKid={onClickKid} />

  <div className="pad-1"></div>
  <div className="am-1">
    <div className="tx-name">Бонусов на счету</div>
    <div className="tx-val">{bonusAmt}</div>
  </div>
  <div className="am-2">
    <div className="tx-name">Тренировок закрыто</div>
    <div className="tx-val">{numClosed}</div>
  </div>
  <div className="pad-2"></div>

  { next1 !== null ? (
  <div className="next">
    <div className="tx-date">{next1}</div>
    <div className="tx-time">{next2}</div>
    <div className="t-1">Следующая тренировка</div>
  </div> ) : null }


  <div className="pad-2"></div>

  <div className="t-9">Тренировок в наличии</div>

    <div style={{flexDirection: 'column', display: 'flex', gap: '18px'}}>
  <TraPlaClosed tit='Общее количество' solo={cnts.solo} pair={cnts.pair} grp={cnts.grp} />
  <TraPlaClosed tit='Неназначенные' solo={cntsOpen.solo} pair={cntsOpen.pair} grp={cntsOpen.grp}  />
  </div>



<div style={{height: '70px'}}></div>

</div>


    );

}


/*
  
  <div className="lay-1">
    <div className="pt-3">
      <div className="tx-name2">Персональных</div>
      <div className="tx-val2">{cnts.solo}</div>
    </div>
    <div className="pt-2">
      <div className="tx-name2">Парных</div>
      <div className="tx-val2">{cnts.pair}</div>
    </div>
    <div className="pt-1">
      <div className="tx-name2">Групповых</div>
      <div className="tx-val2">{cnts.grp}</div>
    </div>
  </div>

*/
