 
export function ArrayOfDictsFindByPropValue(a_ar, a_prop_name, a_prop_value)
{
    if (!a_ar) return null;
    let l = a_ar.length;
    for (let x = 0; x < l; x ++ )
    {
        let e = a_ar[x];
        if (e[a_prop_name] == a_prop_value) return e;
    }
    
    return null;
}
