import React from 'react';




import { DaFaderTop } from "./fader";
import { DaFaderBottom } from "./fader";

export function DaVertScrollFrame( {faders, children} )
{
    /*
    function cb_scr(e)
    {
        //console.log('v scr..', e);
        e.preventDefault();
        e.stopPropagation();
    }
    */

    // create element ref
    /*
    const innerRef = React.useRef(null);

    React.useEffect(() => {
        const div = innerRef.current;
        // subscribe event
        div.addEventListener("scroll", evt);
        div.addEventListener("touchmove", evt);
        div.addEventListener("touchstart", evt);
        div.addEventListener("touchend", evt);
    return () => {
        // unsubscribe event
        div.removeEventListener("scroll", evt);
        div.removeEventListener("touchstart", evt);
        div.removeEventListener("touchend", evt);
    };
    }, []);

    const evt = (e) => {
        
        
        if (e.type === 'scroll')
        {
            //console.log(' prev..');
            
        }

        e.preventDefault();
        //e.preventDefault();
        e.stopPropagation();

        //console.log('tork.. e', e);

        return false;
      }
overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
      */

      //      <DaFaderTop /> 

    return (<div style={{position: 'relative', width: '100%', height: '100%'}}>
                <div style={{webkitoverflowScrolling: 'touch', userSelect: 'none', overscrollBehaviorBlock: 'contain', overscrollBehavior: 'contain', position: 'relative', overflowY: 'scroll', width: '100%', height: '100%'}}>
                <div style={{pointerEvents: 'none'}}>{children}</div>
                </div>
                { faders ? (<>
                           
                                <DaFaderBottom />
                            </>) : null }
            </div>
            );
}

export function DaVertScrollJuice( {hide, children, sx, padding} )
{
    if (hide) return null;


    const st = {position: 'absolute', width: '100%', ...sx };
    
    if (padding) st.padding = padding;
    
    return <div style={st}>{children}</div>;
}




