import React from 'react';

import { LayV, LayH, LaySpace, LayCenter } from './gui_com/layout.js';

import {  DaVertScrollFrame, DaVertScrollJuice } from './gui_com/vscr.js';

import { OvrBusy } from './gui_com/ovr_busy.js';

import { __ActNameFinal } from './shit.js';

import { ArrayOfDictsFindByPropValue } from './helpers.js';

import { SubSchedCalen } from './sch_sub_calen.js';

import { DropList } from './vis/DropList.js';

import { SwitcherLR } from './switcher.js';

import { motion, AnimatePresence } from "framer-motion"

import { LongButton } from './vis/LongButton.js';

import * as DT from './dt.js';

import { UnixTime, DateTimeFmtShort1, DateTimeParseIso, DateTimeFmtHour, DateParseIso, DateFmtLocHum} from './dt.js';

import { TitleKids1, TitleKids2 } from './vis/TitKids.js';

import { MdHome, MdHistory, MdShoppingCart, MdPendingActions  } from "react-icons/md";

import { SubSchedClubs } from './vis/SubSchedClubs.js';

import { ScrNoAuth } from './vis/ScrNoAuth.js';

import { LsItemBuyTrainVis } from './vis/LsItemBuyTrainVis.js';

import { LsItemTrainHistVis } from './vis/LsItemTrainHistVis.js';
import { Title1 } from './vis/Title1.js';
import { Title2 } from './vis/Title2.js';
import { Title3 } from './vis/Title3.js';

import { InfoNoScheds } from './vis/InfoNoScheds.js';

import { StartPageJuice } from './vis/StartPageJuice.js';

import { BottomMenu } from './vis/BottomMenu.js';

import { PromJuice } from './vis/PromJuice.js';

import { ApiReq } from './api.js';


export function CommonReducer(sta, act)
{
	switch(act.type)
	{
		case 'val':
		{
			return { ...sta, [act.k]: act.v };
		}

        case 'vals':
        {
            const n = { ... sta };
            const dct = act.dct;

            for (let k in dct)
            {
                n[k] = dct[k];
            }

            return n;
        }
	}
	
	return sta;
}



//-------------------------------


function ComReducer(sta, act)
{
	switch (act.type)
	{
		case 'val':
		{
			return { ...sta, [act.k]: act.v };
		}

        case 'vals':
        {
            const n = { ... sta };
            const dct = act.dct;
            for (let k in dct)
            {
                n[k] = dct[k];
            }
            return n;
        }
	}
	
	return sta;
}

//--------------------



function MaMenuBtn( {tit, ic, code, isCur, onClick} )
{
    function cb()
    {
        onClick(code);
    }

    const p_ic = React.createElement( ic, {className: 'ic'} );
     
    return ( <div onClick={cb} className={ isCur ? "tg_mm_btn act" : "tg_mm_btn"  }>
        {p_ic}
        <div className="tit" >{tit}</div>
        
    </div> );
}






// <div style={{width: '100%', height: '1px', background: '#303030'}}></div>
function ScrFirst( {kids, onClickKid, ser}  )
{

    const [row, setRow] = React.useState( null );

    React.useEffect( () => {

        //console.log('ScrFirst.. effect.. ', ser);

        function __ok(e)
        {
            //console.log('GOT ROW ! ', e);
            setRow(e.row);
        }

        function __fail(e)
        {
        }

        ApiReq( 'base', {}, __ok, __fail );

    }, [ser] );


    let s_next;

    let next1 = null;
    let next2 = null;

    if (row)
    {
        if (row.next)
        {
            const dt = DateTimeParseIso(row.next.ts_time_start) ;
            s_next = DateTimeFmtShort1( dt ) + ' ' + DateTimeFmtHour( dt );

            next1 = capitalizeFirstLetter( DateTimeFmtShort1( dt ) );
            next2 = DateTimeFmtHour( dt );
        }
        else
        {
            s_next = "---";
        }
    }

    let lele = null;
    let didi = null;

    if (row)
    {
    lele = row.br ? row.br.br_name : null;                // !
    didi = row.br ? row.br.br_add_per_train : 0;
    }

return (

    <LayV rows=' 1fr'>

  

    <DaVertScrollFrame faders>
    <DaVertScrollJuice>

        { row ?
<StartPageJuice kids={kids} onClickKid={onClickKid} dudeName={row.name} levelStr={ lele }  extraBonus={didi}  cntsOpen={row.cnt_open} 
            bonusAmt={row.bonus_amt} numClosed={row.closed}  next1={next1}  next2={next2} cnts={row.cnt} /> : <LaySpace /> }

</DaVertScrollJuice>
</DaVertScrollFrame>


<OvrBusy active={!row} />

</LayV>
);

}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

function TrpStatus( {ty} )
{
    if (ty === 2) return <div style={{color: '#80E070'}}>ЗАКРЫТА</div>;
    if (ty === 3) return <div style={{color: '#FF5030'}}>СГОРЕЛА</div>;
    
    return <div>???</div>;
}





function TrainSchedEntry( {dtIso, traName, actName, club} )
{
    const dt = DateTimeParseIso(dtIso);

    const s_date = DateTimeFmtShort1( dt );
    const s_hour = DateTimeFmtHour( dt );

    const fff = UnixTime( dt ) < UnixTime( new Date() );

    return (
    
        <LsItemTrainHistVis flash={fff} status={1} strTime={s_hour} strDate={s_date} clubName={club} actName={actName} userName={traName} />
    
    );
}



const gSubScreenVariants = {
    prev: { opacity: 0, x: '-100%' },
    cur:  { opacity: 1, x: "0%" },
    next: { opacity: 0, x: '100%' },
};

function SubPageWrapper( {children} )
{

    return (
        <motion.div  style={{height: '100%', width: '100%', position: 'relative'}}
                initial="next" animate="cur" exit='prev' variants={gSubScreenVariants} 
                transition={ {type: "spring", duration: 0.27} } >
            {children}
        </motion.div>
    );
}




function SubSchedList( {onClickReq} )
{
    const [lst, setLst] = React.useState( [] );
    const [ready, setReady] = React.useState( false );


    React.useEffect( () => {

        function __ok(e)
        {
            //console.log('GOT LIST ! ', e);
            setLst(e.trps);
            setReady(true);
        }

        function __fail(e)
        {
            setReady(true);
        }

        ApiReq( 'sched', {}, __ok, __fail );

    }, [] );


    let ar;


    let p_button_shit = null;
    
    

    
    /*
    if (ready)
    {
        p_button_shit =  (<div style={{width: '290px', paddingTop: '40px', paddingBottom: '40px'}}>
                            <LongButton tit='Записаться' onClick={onClickReq}  />

                        </div>);
    }
    
    */



    const IS_EMPTY = lst.length < 1;


    if (ready && IS_EMPTY)
    {
        ar = (<div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <InfoNoScheds />
                {p_button_shit}
             </div>);
    }
    else
    {
        const tm = [];

        for (let x = 0; x < lst.length; x ++ )
        {
            const e = lst[x];

            let act_name = e.ea_name_pub;

            if (e.v_is_reserve) act_name += ' (БРОНЬ)';

            tm.push( <TrainSchedEntry key={x} dtIso={e.ts_time_start} traName={e.u_name} actName={act_name} club={e.v_club}/> );
        }




        ar = (
        <DaVertScrollFrame faders>
            <DaVertScrollJuice sx={{display: 'flex', justifyContent: 'center'}}>
                <div style={{padding: '16px', width: '320px', display: 'flex', flexDirection: 'column' }}>

                    {p_button_shit}

                    {tm}
                </div>
                
                </DaVertScrollJuice>
        </DaVertScrollFrame>
        );
    }

    return (

        <>

        <LayV rows='1fr '>

       

            
         {ar}

     

        </LayV>

        <OvrBusy active={!ready} />

        </>

    );
}


//----------------------

function SingTrainer( {visName, uid, isSel, onClick} )
{
    const CLR = isSel ? '#6A4CD7' : '#FFFFFF';
    
    const TX = isSel ? 'Выбран' : 'Выбрать';

    return (

        <div style={{color: CLR, width: '100%', height: '34px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{fontFamily: 'Montserrat-Bold', fontSize: '14px'}}>{visName}</div>
            <div style={{fontFamily: 'Montserrat-Regular', fontSize: '14px'}}>{TX}</div>
        </div>
    );
}

function TimeSlot( {hr, dis} )
{


    let s = '' + hr;

    if (hr < 10) s = '0' + s;

    return (

        <div className={dis ? "sch_timeslot dis" : 'sch_timeslot'}>{s}:00</div>
    );
}

function SubSchedTrainerAndTime()
{
    const awailHours = [9,10,  15, 16, 17, 18, 20];

    let ar_hr = [];

    for (let h = 9; h < 23; h ++ )
    {
        const DIS = awailHours.indexOf( h ) === -1;

        ar_hr.push( <TimeSlot key={h} hr={h} dis={DIS} /> );
    }


    return (

        <div style={{width: '100%', paddingLeft: '15px', paddingRight: '15px'}}>


            <LayH cols='1fr 320px 1fr' >

                <LaySpace />

                <div style={{width: '100%'}}>

                    <div style={{paddingTop: '24px', paddingBottom: '24px', fontFamily: 'Montserrat-Bold', fontSize: '16px'}}>Доступные тренеры</div>

                    <SingTrainer visName='Евгений'  />
                    <SingTrainer visName='Сурен' isSel />
                    <SingTrainer visName='Владимир'  />
                    <SingTrainer visName='Олег'  />

                    <div style={{width: '100%', height: '30px'}}></div>

                    <SwitcherLR val='5 сентября 2024'/>

                    <div style={{width: '100%', height: '30px'}}></div>


                    <div style={{width: '100%', gap: '8px', justifyContent: 'flex-start', alignContent: 'flex-start',
                                    display: 'flex', flexWrap: 'wrap'}}>
                        {ar_hr}
       

                    </div>

                </div>

                <LaySpace />

            </LayH>

        </div>
    );
}


//----------------------------------------------

const SCH_STP_INIT  = 0;
const SCH_STP_CLUB  = 1;
const SCH_STP_ACT   = 2;
const SCH_STP_CALEN = 3;
const SCH_STP_TRA_TIME = 4;

const gStScheds = {

    step:   SCH_STP_INIT ,
    abons:  null,       // not loaded

    clubId: -1,         // в какой клуб заись
    curTrpId:   -1,     // что выбрал в типах
    curAct:     -1,     // что выбрал в типах

    dctActs: {},

    selected: [],       // корзина трень        { trp, dtStart }

};





function useSchedsState()
{
    const [sta, dispatch] = React.useReducer( CommonReducer, gStScheds );

    function cb_setStep( s )
    {
        dispatch( {type: 'val', k: 'step', v: s} );
    }

    function cb_setVals( dct )
    {
        dispatch( {type: 'vals', dct:dct} );
    }

    function cb_isSelectedTrp( trpId )
    {
        for (let x = 0; x < sta.selected.length; x ++ )
        {
            if (sta.selected[x].trp === trpId) return true;
        }

        return false;
    }

    return {    sta:            sta,
                disp:           dispatch,
                isSelectedTrp:  cb_isSelectedTrp,
                setVals:        cb_setVals,
                setStep:        cb_setStep };
}


function ScrScheds( {hkApp} )
{
    const st = useSchedsState();
    


    function cb_sel_club( kk )
    {
        console.log('selected club.. ', kk);


        st.setVals( {clubId: kk} );
        st.setStep( SCH_STP_ACT );

        function __bk()
        {
            console.log('HEY ! BACK TO CLUBS !');
            st.setStep( SCH_STP_CLUB );
            st.setVals( {abons: null} );    // unload abons
        }

        hkApp.set_back_btn( __bk );
    }


    function cb_req( )
    {
        st.setStep( SCH_STP_CLUB );
    }



    let pp = null;




    if (st.sta.step === SCH_STP_INIT)
    {
        pp = (  <SubPageWrapper  key='list'  >
                    <SubSchedList onClickReq={cb_req}  />
                    </SubPageWrapper>
        );
    }


    if (st.sta.step === SCH_STP_CLUB)
    {
        pp = (       <SubPageWrapper  key='club'  >
        <SubSchedClubs onClick={cb_sel_club} />
    </SubPageWrapper>);
    }

    if (st.sta.step === SCH_STP_ACT)
        {
            pp = (
                <SubPageWrapper  key='act' >
                    <SubSchedTypes st={st} hkApp={hkApp}  />
                </SubPageWrapper>);
        }

    if (st.sta.step === SCH_STP_CALEN)
    {
        pp = (
            <SubPageWrapper  key='calen' >
                <SubSchedCalen st={st} hkApp={hkApp}  />
            </SubPageWrapper>);
    }

    if (st.sta.step === SCH_STP_TRA_TIME)
    {
        pp = (
            <SubPageWrapper  key='susu' >
                <SubSchedTrainerAndTime />
            </SubPageWrapper>);
    }
    
    return (
        <AnimatePresence mode='wait'  initial={false}  >
            
            {pp}

      </AnimatePresence>

    );



}



function __abonShortName( sn )
{
    const aa = [];

    for (let k in sn)
    {
        const e = sn[k];

        if (e.cnt > 1)
        {
            aa.push(`${e.name} x${e.cnt}`);
        }
        else
        {
            aa.push(e.name);
        }
    }

    return aa.join(', ');
}





function __makeDropListForAbon( abon, st, onSel )
{
    const ss = [];

    const actsAdded = [];

    const DCT_ACTS = st.sta.dctActs;

    //console.log('MAKE DROP...');

    for (let x = 0; x < abon.trps.length; x ++ )
    {
        const T = abon.trps[x];

        //console.log(' ITER ', T);

        const isSel = st.isSelectedTrp(  T.trp_id );
        if (isSel) continue;

        //console.log('  CSV ', T.trp_acts_csv);

        const acts_csv = T.trp_acts_csv.split(',');

        for (let i = 0; i < acts_csv.length; i ++ )
        {
            const act = acts_csv[i];
            if (actsAdded.indexOf(act) !== -1) continue;    // already added

            actsAdded.push( act );

            const NAM = __ActNameFinal( DCT_ACTS[act] );

            ss.push( {  n: NAM,  v: T.trp_id, v2: act } );
        }

        
    }

    const NAM = __abonShortName( abon.sn );



    return <DropList key={abon.pay_id}  tit={NAM}        src={ ss } onClick={onSel} />;

}

function SubSchedTypes( {st, hkApp} )
{
    const CI = st.sta.clubId;

    const [busy, setBusy] = React.useState( false );

    console.log('SCHED TYPES.. CLUB IS: ', CI);

    React.useEffect( () => {

        if (st.sta.abons !== null)
        {
            console.log('no need to load abons !');
            return;
        }

        console.log('LOAD ABONS !');

        function __ok(e)
        {
            console.log('GOT ABONS', e);

            st.setVals( {abons:e.abons, dctActs: e.dctActs} );

            setBusy( false );
        }

        function __fail(e)
        {
            setBusy( false );
        }

        setBusy( true );

        ApiReq( 'schLdAv', {ci:CI}, __ok, __fail );

    }, [st, st.sta.abons, CI] );


    function cb_onSelTrp(trpId, act)
    {
        console.log('TORK ', trpId, ' --- ', act);

        st.setVals( {curTrpId: trpId, curAct: parseInt(act) } );
        st.setStep( SCH_STP_ACT );

        function __bk()
        {
            console.log('HEY ! BACK TO TYPES');
            st.setStep( SCH_STP_ACT );
            st.setVals( {curTrpId: -1, curAct: -1} );
        }

        hkApp.set_back_btn( __bk );

        st.setStep( SCH_STP_CALEN );
    }

    const p_lst = [];

    {
        const ABONS = st.sta.abons;

        if (ABONS !== null)
        {
            for (let x = 0; x < ABONS.length; x ++ )
            {
                const AA = ABONS[x];

                const p = __makeDropListForAbon( AA, st, cb_onSelTrp );

                if (p !== null) p_lst.push( p );
            }
        }
    }

    return (
        
        <div style={{width: '100%', height: '100%', position: 'relative'}}>

        <DaVertScrollFrame faders>

            <DaVertScrollJuice  >

            

                <div style={{pointerEvents: 'all', paddingTop: '20px', paddingBottom: '60px', display: 'flex', justifyContent: 'center'}}>


                    <div style={{ width: '290px', height: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}>

                            <div style={{paddingTop: '16px', paddingBottom: '16px', width: '290px', fontSize: '14px', fontFamily: 'Montserrat-Regular'}}>Выбери тип тренировки</div>

                    

                            {p_lst}

                    </div>

                </div>



            </DaVertScrollJuice>

        </DaVertScrollFrame>


        <OvrBusy active={busy} />

        </div>
    );
}


//----------------------------------


function TrainHistEntry( {dtIso, traName, actName, trpSta, club} )
{
    const dt = DateTimeParseIso(dtIso);

    const s_date = DateTimeFmtShort1( dt );
    const s_hour = DateTimeFmtHour( dt );

    return <LsItemTrainHistVis status={trpSta} strDate={s_date} strTime={s_hour} userName={traName} actName={actName} clubName={club} />;

}



function ScrPrevTrains( )
{
    const [lst, setLst] = React.useState( [] );
    const [ready, setReady] = React.useState( false );

    React.useEffect( () => {

        function __ok(e)
        {
            //console.log('GOT LIST ! ', e);
            setLst(e.trps);
            setReady(true);
        }

        function __fail(e)
        {
            setReady(true);
        }

        ApiReq( 'past', {}, __ok, __fail );

    }, [] );


    const ar = [];

    for (let x = 0; x < lst.length; x ++ )
    {
        const e = lst[x];

        ar.push( <TrainHistEntry key={x} dtIso={e.ts_time_start}  club={e.v_club} trpSta={e.trp_status} traName={e.u_name} actName={e.ea_name_pub} /> );
    }

    return (
        <>
        
        <LayV rows=' 1fr'>

        

        <DaVertScrollFrame faders>
            <DaVertScrollJuice sx={{display: 'flex', justifyContent: 'center'}}>
            
            
        
                
                <div style={{padding: '16px', width: '320px', display: 'flex', flexDirection: 'column'}}>
                    {ar}
                    
                </div>

                
                
                </DaVertScrollJuice>
        
        </DaVertScrollFrame>


        

        </LayV>

        <OvrBusy active={!ready} />

        </>
    
    );
}



function BuyAmo( {amo} )
{
    if (amo < 2) return null;
    
    return <span style={{paddingLeft: '4px', color: 'var(--fl-clr-primary)'}}>x{amo}</span>;
}


function BuySpendLine( {num, what, clr, sign} )
{
    if (!num) return null;
    
    return <div style={{color: clr ? clr : '#A0A0A0'}}>{sign ? sign : ''}{num} {what}</div>;
}


function AbonName( {dct} )
{
    let ar = [];

    for (let k in dct)
    {
        const e = dct[k];
        let s;

        if (e.cnt === 1)
        {
            ar.push( <div key={k} >{e.name}</div> );
        }
        else
        {
            ar.push( <div key={k}><span>{e.name}</span><span style={{paddingLeft: '4px', color: 'var(--fl-clr-primary)'}}>x{e.cnt}</span></div> );
        }

    }

    return ar;
}

function BuyEntry( {dt, name, amo, spentMoney, spentBal, spentBon, gainBonus} )
{
    dt = DateTimeParseIso( dt );

    dt = DateFmtLocHum( dt );

    if (!gainBonus) gainBonus = 0;


    return <LsItemBuyTrainVis dtBuy={dt} reward={gainBonus} money={spentMoney} spendBon={spentBon} name={name} />;
}


function ScrPrevBuys( )
{
    const [lst, setLst] = React.useState( [] );
    const [ready, setReady] = React.useState( false );

    React.useEffect( () => {

        function __ok(e)
        {
            //console.log('GOT LIST ! ', e);
            setLst(e.rows);
            setReady(true);
        }

        function __fail(e)
        {
            setReady(true);
        }

        ApiReq( 'buys', {}, __ok, __fail );

    }, [] );


    const ar = [];

    for (let x = 0; x < lst.length; x ++ )
    {
        const e = lst[x];

        ar.push( <BuyEntry key={x} name={e.short} amo={99} dt={e.v_time} 
                                        gainBonus={e.v_bon_reward}  
                                        spentMoney={e.spend_money} 
                                        spentBal={e.spend_bal} 
                                        spentBon={e.spend_bon} /> );
    }

    return (

        <>
        
        <LayV rows='1fr'>

        

        <DaVertScrollFrame faders>
            <DaVertScrollJuice sx={{display: 'flex', justifyContent: 'center'}}>
            
            
        
                
                <div style={{padding: '16px', width: '320px', display: 'flex', flexDirection: 'column' }}>
                    
                    {ar}

                </div>
                
                </DaVertScrollJuice>
        
        </DaVertScrollFrame>



        </LayV>

        <OvrBusy active={!ready} />

        </>
    
    );
}



function ScrProm()
{
    return (



    <React.Suspense fallback={ <OvrBusy active={true} /> }>

    <DaVertScrollFrame faders>
    <DaVertScrollJuice>

        <PromJuice />

</DaVertScrollJuice>
</DaVertScrollFrame>

        </React.Suspense>

    );
}


const gItems = [


    { n: 'БАЗА',        c: 'baza',          cla: ScrFirst,      ic:MdHome },
    { n: 'ИСТОРИЯ',   c: 'hist_train',      cla: ScrPrevTrains, ic:MdHistory },
    { n: 'ПОКУПКИ',   c: 'hist_buy',        cla: ScrPrevBuys,   ic:MdShoppingCart },
    { n: 'ЗАПИСИ',      c: 'sched',         cla: ScrScheds,     ic:MdPendingActions },
    { n: 'АКЦИИ',     c: 'prom',            cla: ScrProm,   ic: null },


];

// const wa = window.Telegram.WebApp;

function ScrBoot( {m} )
{
    const WA = window.Telegram.WebApp;

    WA.ready();
    WA.expand();
    WA.headerColor = '#0F0F0F'; // #6A4CD7

    WA.BackButton.isVisible = false;
    WA.SettingsButton.isVisible = false;

    /*
    WA.MainButton.hide();
    WA.MainButton.disable();

    WA.MainButton.isActive = false;
    WA.MainButton.isVisible = false;
    */

    /*
    WA.MainButton.setParams( {

            text: 'YO',
            is_visible: false,
    });
    */

    return ( 
                m === 2 ? <ScrNoAuth /> : <div style={{background: '#0F0F0F', width: '100%', height: '100%'}}></div>

    );
}




const gSta = {
  
    scrCode:  'baza',
    mode: 0,

    busy: false,

    ser: 0,

    isKidMode:  false,
    kidName: 'UNK UNK',     // cur

    kids: [],

    backBtnAct: null,
};



function useAppState()
{
    const [sta, dispatch] = React.useReducer( ComReducer, gSta );

    //console.log('USE APP STATE..');

    function cb_click_item(kk)
    {
        dispatch( {type: 'val', k:'scrCode', v: kk} );
    }

    function cb_set_back_btn( v )
    {

        dispatch( {type: 'val', k:'backBtnAct', v: v} );
    }


    function cb_close_kid()
    {
        //console.log('close kid !');
        function __ok(e)
        {
            dispatch( {type: 'vals', dct: {busy: false, scrCode: 'baza', ser: sta.ser+1, isKidMode: false, kidName: '---'} } );
        }

        function __fail(e)
        {
            dispatch( {type: 'vals', dct: {busy: false} } );
        }

        dispatch( {type: 'vals', dct: {busy: true} } );

        ApiReq( 'changeKid', {ii:0}, __ok, __fail );
    }

    function cb_click_kid( iii )
    {
        console.log('KID ! ', iii);

        function __ok(e)
        {
            const K = ArrayOfDictsFindByPropValue( sta.kids, 'cu_id', iii);

            const namnam = K ? K.cu_name : '???';

            dispatch( {type: 'vals', dct: {ser: sta.ser+1, busy: false, isKidMode: true, kidName: namnam} } );
        }

        function __fail(e)
        {
            dispatch( {type: 'vals', dct: {busy: false} } );
        }

        dispatch( {type: 'vals', dct: {busy: true} } );

        ApiReq( 'changeKid', {ii:iii}, __ok, __fail );
        
    }


    return {    sta:sta,
                disp: dispatch,
                close_kid:cb_close_kid,
                set_back_btn: cb_set_back_btn,
                click_item:cb_click_item,
                click_kid:cb_click_kid  };
}

//-----------------------------------------------------


function MyHeadImp( {hk} )
{
    let p_head = <LaySpace />;

    const sta = hk.sta;

    if (sta.scrCode === 'hist_train')
    {
        if (!sta.isKidMode)
        {
            p_head = <Title2 tit='Предыдущие тренировки' desc='максимум 20'/>;
        }
        else
        {
            p_head = <TitleKids2 tit='Предыдущие тренировки' nam={sta.kidName}  onClose={hk.close_kid}  />;
        }
    }
    else if (sta.scrCode === 'hist_buy')
    {
        if (!sta.isKidMode)
        {
            p_head =  <Title2 tit='Твои покупки' desc='20 последних' />;
        }
        else
        {
            p_head = <TitleKids1 tit='Покупки' nam={sta.kidName} onClose={hk.close_kid}   />;
        }
    }
    else if (sta.scrCode === 'sched')
    {

        if (!sta.isKidMode)
        {
            p_head =  <Title3 tit='Предстоящие тренировки' onBack={sta.backBtnAct}  />;
        }
        else
        {
            p_head = <TitleKids2 tit='Предстоящие тренировки' nam={sta.kidName} onClose={hk.close_kid}   />;
        }
    }
    else if (sta.scrCode === 'baza' )
    {
        if (!sta.isKidMode)
        {
            p_head =   <Title1 tit='Профиль' />;
        }
        else
        {
            p_head = <TitleKids1 tit='Профиль' nam={sta.kidName} onClose={hk.close_kid}   />;
        }
    }
    else if (sta.scrCode === 'prom')
    {
        if (!sta.isKidMode)
        {
         p_head =  <Title1 tit='Акции' />;

        }
        else 
        {
            p_head = <TitleKids1 tit='Акции' nam={sta.kidName} onClose={hk.close_kid}   />;
        }
    }

    return p_head;
}


const MyHead = React.memo( MyHeadImp );

//-------------------



export function App()
{
    const WA = window.Telegram.WebApp;


    const hk = useAppState();

    const dispatch  = hk.disp;
    const sta       = hk.sta;


    

    React.useEffect( () => {

        function __ok(e)
        {
            console.log('set kids', e.kids);

            dispatch( {type: 'vals', dct: {mode:1, busy: false, kids: e.kids} } );
        }

        function __fail(e)
        {
            dispatch( {type: 'vals', dct: {mode:2, busy: false}  } );
        }

        const WW = document.getElementById('root').getBoundingClientRect().weight;
        const HH = document.getElementById('root').getBoundingClientRect().height;

        dispatch( {type: 'vals', dct: {busy: true}  } );

        ApiReq( 'boot', {ww: WW, hh: HH, plat: WA.platform, ver: WA.version, initData:WA.initData}, __ok, __fail );

    }, [] );


    let p_juice = null;
    
    if (sta.mode !== 1)
    {
        p_juice = <ScrBoot m={sta.mode} />;
    }
    else
    {
        let p_head = <MyHead hk={hk} />;

        //let p_scr = <LaySpace />;

    
        const elem = ArrayOfDictsFindByPropValue(gItems, 'c', sta.scrCode);


        const PPP = {   kids:         sta.kids,
                        hkApp: hk,
                        ser: sta.ser,
                        onClickKid: sta.isKidMode ? null : hk.click_kid
                    };
    
    
        const p_scr =  React.createElement( elem.cla, PPP, null);
    


        p_juice = (

                <LayV rows='max-content 1fr max-content'>
        
                    {p_head}
        
                    <div  style={{background: '#0F0F0F', width: '100%', height: '100%', position: 'relative'}}>
                        
                       {p_scr}
                    </div>
        
                    <BottomMenu  onClick={hk.click_item}  curCode={sta.scrCode} />
        
                </LayV>
    

        );
    }

    return (
     
        <>
        
        
        {p_juice}
        
        <OvrBusy active={sta.busy} />

        </>
        
        
        
    );
}





export function App2()
{
    //return <ScrDunno />;
    //console.log('App !');

    const WA = window.Telegram.WebApp;

    const [sta, dispatch] = React.useReducer( ComReducer, gSta );
    

    React.useEffect( () => {


        function __ok(e)
        {
            //const chch = CsvSplit( e.chIdsCsv );
            
            //console.log('CHILDREN: ', chch);

            console.log('set kids', e.kids);

            dispatch( {type: 'vals', dct: {mode:1, kids: e.kids} } );
        }

        function __fail(e)
        {
            dispatch( {type: 'val', k: 'mode', v: 2 } );
        }

        ApiReq( 'boot', {plat: WA.platform, ver: WA.version}, __ok, __fail );


    }, [] );


    if (sta.mode !== 1) return <ScrBoot m={sta.mode} />;

    function cb_click_item(kk)
    {
        dispatch( {type: 'val', k:'scrCode', v: kk} );

        //window.Telegram.WebApp.HapticFeedback.selectionChanged();
       // window.Telegram.WebApp.themeParams.header_bg_color = '#FF0000';
        //window.Telegram.WebApp.themeParams.bg_color = '#FFFF00';
        //window.Telegram.WebApp.themeParams.text_color = '#FF00FF';
 
        
        /*
        WA.MainButton.isActive = false;
        WA.MainButton.isVisible = false;
        WA.BackButton.isVisible = false;
        WA.SettingsButton.isVisible = false;
    
        WA.MainButton.hide();
        WA.MainButton.disable();
        */
    }


    function cb_click_kid( iii )
    {
        //console.log('KID ! ', iii);

        function __ok(e)
        {
            //console.log(e);

            const K = ArrayOfDictsFindByPropValue( sta.kids, 'cu_id', iii);

            const namnam = K ? K.cu_name : '???';

            dispatch( {type: 'vals', dct: {isKidMode: true, kidName: namnam} } );
        }

        function __fail(e)
        {
            //dispatch( {type: 'val', k: 'mode', v: 2 } );
        }

        ApiReq( 'changeKid', {ii:iii}, __ok, __fail );
        
    }

    
    let arr = [];
    
    for (let x = 0; x < gItems.length; x ++ )
    {
        const e = gItems[x];
        arr.push( <MaMenuBtn key={x} ic={e.ic} code={e.c} tit={e.n} isCur={sta.scrCode===e.c} onClick={cb_click_item} /> );
    }

    
    const elem = ArrayOfDictsFindByPropValue(gItems, 'c', sta.scrCode);


    const PPP = {   kids:         sta.kids,
                    onClickKid: sta.isKidMode ? null : cb_click_kid
                };


    const p_scr =  React.createElement( elem.cla, PPP, null);


    let p_head = null;

    if (sta.scrCode === 'hist_train')
    {
        if (!sta.isKidMode)
        {
            p_head = <Title2 tit='Предыдущие тренировки' desc='максимум 20'/>;

        }
        else
        {
            p_head = <TitleKids2 tit='Предыдущие тренировки' nam={sta.kidName}  />;
        }
    }
    else if (sta.scrCode === 'hist_buy')
    {
        if (!sta.isKidMode)
        {
            p_head =  <Title2 tit='Твои покупки' desc='20 последних' />;
        }
        else
        {
            p_head = <TitleKids1 tit='Покупки' nam={sta.kidName}  />;
        }
    }
    else if (sta.scrCode === 'sched')
    {


        if (!sta.isKidMode)
        {
            p_head =  <Title3 tit='Предстоящие тренировки'  />;
        }
        else
        {
            p_head = <TitleKids2 tit='Предстоящие тренировки' nam={sta.kidName}  />;
        }
    }
    else if (sta.scrCode === 'baza' )
    {
        if (!sta.isKidMode)
        {
            p_head =   <Title1 tit='Профиль' />;
        }
        else
        {
            p_head = <TitleKids1 tit='Профиль' nam={sta.kidName}  />;
        }
    }
    else if (sta.scrCode === 'prom')
    {
        if (!sta.isKidMode)
        {
         p_head =  <Title1 tit='Акции' />;

        }
        else 
        {
            p_head = <TitleKids1 tit='Акции' nam={sta.kidName}  />;
        }
    }
    

    return (
        <LayV rows='max-content 1fr max-content'>

            {p_head}

            <div  style={{background: '#0F0F0F', width: '100%', height: '100%', position: 'relative'}}>
                
               {p_scr}
            </div>

            <BottomMenu  onClick={cb_click_item}  curCode={sta.scrCode} />

        </LayV>
  );
}

