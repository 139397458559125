import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.css';
import { App } from './app';


const e = document.getElementById('root');

//e.addEventListener('touchmove', fnc, {passive: true} );
//e.addEventListener('scroll', fnc, {passive: true} );

const root = ReactDOM.createRoot( e );

root.render(
 
    <App />

);
