import "./LsItemTrainHistVis.css";

export const LsItemTrainHistVis = ({
  strTime = "12:00",
  strDate = "Вт 30.11",
  actName = "??Единоборства - персональная",
  clubName = "ИмяКлуба",
  userName = "ИмяТренахи",
  className,
  flash = false,
  status,
  ...props
}) => {


    let p_sta = null;

    if (status === 2 || status === 4)   // 4 ! BURNED
    {
        p_sta = (
    <div className="zakrita">
        <div className="div">Закрыта </div>
        <svg
          className="ic-lock"
          width="20"
          height="24"
          viewBox="0 0 20 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.60005 8.79999V7.88571C6.60005 4.8468 9.00862 2.39999 12 2.39999C14.9915 2.39999 17.4001 4.8468 17.4001 7.88571V8.79999M6.60005 8.79999C5.61005 8.79999 4.80005 9.62285 4.80005 10.6286V19.7714C4.80005 20.7771 5.61005 21.6 6.60005 21.6H17.4001C18.3901 21.6 19.2001 20.7771 19.2001 19.7714V10.6286C19.2001 9.62285 18.3901 8.79999 17.4001 8.79999M6.60005 8.79999H17.4001M12 16.2V13.8"
            stroke="#6A4CD7"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </div>
        );
    }
    
    let clclcl = ' ';

    if (flash)
    {
        clclcl = 'yy_pulse_opa ';
    }

  return (
    <div className={clclcl + "ls-item-train-hist-vis " + className}>
      <div className="act-text">{actName} </div>
      {p_sta}
      <div className="tratra">
        <svg
          className="ic-dude"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.23779 19.5C4.5632 17.2892 7.46807 15.7762 12.0001 15.7762C16.5321 15.7762 19.4369 17.2892 20.7623 19.5M15.6001 8.1C15.6001 10.0882 13.9883 11.7 12.0001 11.7C10.0118 11.7 8.40007 10.0882 8.40007 8.1C8.40007 6.11177 10.0118 4.5 12.0001 4.5C13.9883 4.5 15.6001 6.11177 15.6001 8.1Z"
            stroke="#6A4CD7"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <div className="tx-tra-name">{userName} </div>
      </div>
      <div className="adr">
        <svg
          className="ic-loc"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21.6C12 21.6 19.513 14.9217 19.513 9.91304C19.513 5.7637 16.1493 2.39999 12 2.39999C7.85064 2.39999 4.48694 5.7637 4.48694 9.91304C4.48694 14.9217 12 21.6 12 21.6Z"
            stroke="#6A4CD7"
            strokeWidth="2"
          />
          <path
            d="M14.4003 9.60015C14.4003 10.9256 13.3258 12.0001 12.0003 12.0001C10.6748 12.0001 9.60029 10.9256 9.60029 9.60015C9.60029 8.27466 10.6748 7.20015 12.0003 7.20015C13.3258 7.20015 14.4003 8.27466 14.4003 9.60015Z"
            stroke="#6A4CD7"
            strokeWidth="2"
          />
        </svg>
        <div className="tx-club-name">{clubName} </div>
      </div>
      <div className="when">
        <div className="dt">{strDate} </div>
        <div className="dot"></div>
        <div className="tm">{strTime} </div>
      </div>
    </div>
  );
};
