 
import React from 'react';



import "./KidsPlashkaOpen.css";


import { IcArrowUp, IcArrowClosed } from './TraPlaClosed.js';



export function KidsPlashkaOpen( { src, onClickKid } )
{
    const [isOpen, setIsOpen] = React.useState( false );


    if (!onClickKid || src.length < 1) return null;

    
    function cb_click()
    {
        setIsOpen( !isOpen );
    }
    

    
    const ar = [];

    const NUM = src.length;
    
    for (let x = 0; x < NUM; x ++ )
    {
        const e = src[x];

        function cb_click_me()
        {
            onClickKid( e.cu_id );
        }
        
        ar.push(  <div key={x} style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        
            <div className="kids-plashka-open__kids-name">{e.cu_name}</div>
            <div className="kids-plashka-open__kids-enter" onClick={cb_click_me} >Войти</div>
        
        </div>
        
        );
    }
    
    
    let CLACLA = "kids-plashka-open";
    
    if (!isOpen) CLACLA += ' cloclo';
    
    return (
    
<div className={CLACLA} style={{height: isOpen ? (40+NUM*34) + 'px' : '40px'}}>
  <div className="kids-plashka-open__kids-title" onClick={cb_click} >Дети</div>
  { isOpen ? <IcArrowUp /> : <IcArrowClosed />  }
  <div className="kids-plashka-open__juice">
        {ar}
  </div>
</div>

    
    
    );
}
