import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import "./PromJuice.css";
import { OvrBusy } from '../gui_com/ovr_busy';




const imgCache = {
  __cache: {},
  read(src) {
    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          this.__cache[src] = true;
          resolve(this.__cache[src]);
        };
        img.src = src;
      }).then((img) => {
        this.__cache[src] = true;
      });
    }
    if (this.__cache[src] instanceof Promise) {
      throw this.__cache[src];
    }
    return this.__cache[src];
  }
};

export const SuspenseImg = ({ src }) => {
  imgCache.read(src);
  return <img className="img_hq" src={src} alt='' style={{ objectFit: 'contain', imageRendering: 'high-quality', transform: 'translateZ(1px)', 
                                                      maxWidth: '100%', maxHeight: '100%', width: '100%'}}    />;
};
   
//  <SuspenseImg src='prom_bands.webp?huy=2' />

//    <div className="prom-juice__empty">Пока ничего!</div>
 
export function PromJuice()
{

    return (


   
<div className="prom-juice">


<div className="prom-juice__f-fre">
    <SuspenseImg className="prom-pic" src="bd.webp" />
    <div className="prom-text">
    Получи скидку 10% на тренировки в течение трёх дней до и после твоего дня рождения!
    </div>
    <div className="prom-juice__div6"></div>
  </div>



  <div className="prom-juice__f-fre">
    <SuspenseImg className="prom-juice__prom-pic2" src="2fre.webp" />
    <div className="prom-juice__div3">
      Приведи друга на пробную тренировку и получите по 300 бонусов
       при первой покупке любого количества тренировок в первый день.
    </div>
    <div className="prom-juice__div6"></div>
  </div>

  <div className="prom-juice__f-rev">
    <SuspenseImg className="prom-juice__prom-pic2" src="4rev.webp" />
    <div className="prom-juice__vk-2-10">
      Оставь честный отзыв о нашем клубе в VK и на Яндекс Картах (2ГИС) и мы
      подарим тебе скидку 5% на любые виды тренировок.
    </div>
    <div className="prom-juice__div6"></div>
  </div>


</div>


    )

}


/*
<div className="prom-juice">
  <div className="prom-juice__f-bint">
    <SuspenseImg className="prom-juice__prom-pic" src="1bint.webp" />
    <div className="prom-juice__frame-28">
      <div className="prom-juice__frame-27">
        <div className="prom-juice__div">
          Отметь нас в ВК и Инсте и получи комплект бинтов в подарок!
          <br />
          <br />
          Акция действует только при первой пробной тренировке.
          <br />
          <br />
          Принимаются только фотографии и видеоролики из нашего клуба.
        </div>
        <div className="prom-juice__div2">Акция действует до конца марта.</div>
      </div>
      <div className="prom-juice__line-1"></div>
    </div>
  </div>


  <div className="prom-juice__f-fre">
    <SuspenseImg className="prom-juice__prom-pic2" src="2fre.webp" />
    <div className="prom-juice__div3">
      Приходи с другом на бесплатную пробную тренировку и получите ещё по одной
      парной в подарок!
    </div>
    <div className="prom-juice__div4">Акция действует до конца марта.</div>
  </div>


  <div className="prom-juice__f-mark">
    <SuspenseImg className="prom-juice__prom-pic2" src="3mark.webp" />
    <div className="prom-juice__div3">
      Отметь нас в любой соц. сети и получи подарок на выбор: батончик, напиток
      или мини стикерпак!
      <br />
      <br />
      Принимаются только фотографии и видеоролики из нашего клуба.
    </div>
    <div className="prom-juice__div5">Акция действует до конца марта.</div>
  </div>
  <div className="prom-juice__f-rev">
    <SuspenseImg className="prom-juice__prom-pic2" src="4rev.webp" />
    <div className="prom-juice__vk-2-10">
      Оставь честный отзыв о нашем клубе в VK и на Яндекс Картах (2ГИС) и мы
      подарим тебе скидку 10% на любые виды тренировок.
    </div>
    <div className="prom-juice__div6">Акция действует до конца марта.</div>
  </div>
</div>

*/
