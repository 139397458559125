

import "./InfoNoScheds.css";

export function InfoNoScheds( )
{
    return (
    
 
<div className="info-no-scheds">
  <div className="info-no-scheds__tit">Пусто</div>
  <div className="info-no-scheds__tx">У вас нет активных записей.</div>
  <svg
    className="info-no-scheds__ic-no-shit"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2857 23.5714H49.5M20.4286 9V13.3719M51 9V13.3714M60 16.3714L60 57C60 58.6569 58.6569 60 57 60H15C13.3431 60 12 58.6569 12 57V16.3714C12 14.7146 13.3431 13.3714 15 13.3714H57C58.6569 13.3714 60 14.7146 60 16.3714Z"
      stroke="#6A4CD7"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
</div>

    
    );
}
