 

import "./Title3.css"

import  { HeadBtnBack } from './HeadBtnBack.js';



export function Title3( {tit, onBack} )
{
 // console.log('TITLE 3', onBack);

    return (
<div className="title-3">
  <div className="tt">{tit}</div>
  <HeadBtnBack onBack={onBack}/>
</div>

    );
}