import "./BottomMenuItem.css";


export function BottomMenuItem( {isCur, title, code, onClick, ic} )
{
  function cb_clk()
  {
    console.log('CLK ', code);
    onClick( code );
  }

    return (


<div className="bottom-menu-item" onClick={cb_clk} >
   { isCur ? (
  <div className="lite"></div>
    ) : null }
    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '16px'}}>
  { ic }
  </div>
  <div className="title">{title}</div>
</div>

    );
}