 


export function GetBackendBaseURL()
{
    if (window.location.hostname === 'crmdev.net') return 'http://crmdev.net:10002/';
    //return 'https://185.104.113.37/';
    
    return '';
}


export function ApiReq(cmd, prm, onOk, onFail)
{
    function WrapSucc(e)
    {
        if (e.c !== 1)
        {
            console.log('API ERROR: ', e.m);
            if (onFail) onFail( e );
            return;
        }
        
        if (onOk) onOk(e);
    }
    
    function WrapErr(e)
    {
        if (e.c !== undefined)
        {
            if (onFail) onFail( e );
            return;
        }
        
        let r = { m: 'NETWORK FAIL' };
        
        if (onFail) onFail( r );
    }
    
    const TG_INIT = window.Telegram.WebApp.initData;
    
    const my_data = { cmd: cmd,
                      prm: prm,
                    tgInit: TG_INIT };
                    
    // 
    
    const trg = GetBackendBaseURL() + 'rq';
    
    fetch(trg, {
        //mode: 'cors',
        cache: 'no-store',
        method: 'POST',
        //credentials: "include",
        
        headers:{'content-type': 'application/json'},
        

        body: JSON.stringify(my_data),
    })
    .then(response => {
        
        //console.log('GOT RESP ', response);
        
        return response.json()
        
        })
    .then(data => {
        
        if (data.c === 1)
        {
            WrapSucc(data);
            return;
        }
        else
        {
            console.warn('REQUEST API ERROR: ', data);
            WrapErr(data);
        }
        
         
    })
    .catch((error) => {
        console.warn( 'REQUEST ERROR ', error );
        WrapErr( error );
    });
    

}
