
import { BottomMenuItem } from './BottomMenuItem.js';

import "./BottomMenu.css";


//--------

function IcClock()
{
  return (
<svg
  className="ic-clock"
  width="26"
  height="26"
  viewBox="0 0 26 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.5431 15.9799C17.0671 16.1546 17.6334 15.8714 17.8081 15.3475C17.9827 14.8235 17.6995 14.2572 17.1756 14.0826L16.5431 15.9799ZM13.0625 13.7656H12.0625C12.0625 14.1961 12.3379 14.5782 12.7463 14.7143L13.0625 13.7656ZM14.0625 8.47348C14.0625 7.92119 13.6148 7.47348 13.0625 7.47348C12.5102 7.47348 12.0625 7.92119 12.0625 8.47348H14.0625ZM17.1756 14.0826L13.3787 12.8169L12.7463 14.7143L16.5431 15.9799L17.1756 14.0826ZM14.0625 13.7656V8.47348H12.0625V13.7656H14.0625ZM22.1875 12.5C22.1875 17.5396 18.1021 21.625 13.0625 21.625V23.625C19.2067 23.625 24.1875 18.6442 24.1875 12.5H22.1875ZM13.0625 21.625C8.0229 21.625 3.9375 17.5396 3.9375 12.5H1.9375C1.9375 18.6442 6.91833 23.625 13.0625 23.625V21.625ZM3.9375 12.5C3.9375 7.4604 8.0229 3.375 13.0625 3.375V1.375C6.91833 1.375 1.9375 6.35583 1.9375 12.5H3.9375ZM13.0625 3.375C18.1021 3.375 22.1875 7.4604 22.1875 12.5H24.1875C24.1875 6.35583 19.2067 1.375 13.0625 1.375V3.375Z"
    fill="white"
  />
</svg>

  );
}

function IcHome()
{
  return (
<svg
  className="ic-home"
  width="26"
  height="26"
  viewBox="0 0 26 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.75 22.75V15.3322C9.75 14.6494 10.332 14.0959 11.05 14.0959H14.95C15.668 14.0959 16.25 14.6494 16.25 15.3322V22.75M12.2466 3.47877L3.79662 9.1932C3.45368 9.42511 3.25 9.80054 3.25 10.2007V20.8956C3.25 21.9197 4.12305 22.75 5.2 22.75H20.8C21.877 22.75 22.75 21.9197 22.75 20.8956V10.2007C22.75 9.80054 22.5463 9.42511 22.2034 9.1932L13.7534 3.47877C13.3023 3.17374 12.6977 3.17374 12.2466 3.47877Z"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
  />
</svg>

  );
}


function IcCalen()
{
  return (

    <svg
  className="ic-calen"
  width="26"
  height="26"
  viewBox="0 0 26 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.71875 19.0902V19M13.7812 19.0902V19M13.7812 14.2V14.1098M18.2812 14.2V14.1098M5.34375 9.39996H21.0937M7.37946 2.5V4.30021M18.8437 2.5V4.29999M18.8437 4.29999H7.59375C5.72979 4.29999 4.21875 5.91176 4.21875 7.89997V19.9C4.21875 21.8882 5.72979 23.5 7.59375 23.5H18.8437C20.7077 23.5 22.2187 21.8882 22.2187 19.9L22.2187 7.89997C22.2187 5.91176 20.7077 4.29999 18.8437 4.29999Z"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>


  );
}


function IcBuy()
{
  return (

    <svg
  className="ic-bag"
  width="26"
  height="26"
  viewBox="0 0 26 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M22.6003 7.29992L3.40091 7.29964L3.39966 7.29998M22.6003 7.29992L22.5997 20.616C22.5997 21.8774 21.5577 22.9 20.2724 22.9H5.72693C4.44161 22.9 3.39966 21.8774 3.39966 20.616V7.29998M22.6003 7.29992L18.7511 3.45145C18.5261 3.2264 18.2209 3.09998 17.9026 3.09998H8.09671C7.77845 3.09998 7.47323 3.2264 7.24819 3.45145L3.39966 7.29998M16.5997 10.9C16.5997 12.8882 14.9879 14.5 12.9997 14.5C11.0114 14.5 9.39966 12.8882 9.39966 10.9"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>


  );
}

function IcStar()
{
  return (
<svg
  className="ic-star"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>

    <svg y="1">

  <path
    d="M11.4951 1.7139C11.7017 1.29536 12.2985 1.29536 12.5051 1.7139L15.1791 7.13203C15.2611 7.29823 15.4196 7.41343 15.6031 7.44008L21.5823 8.30892C22.0442 8.37604 22.2286 8.94366 21.8944 9.26945L17.5678 13.4869C17.4351 13.6162 17.3745 13.8026 17.4058 13.9853L18.4272 19.9404C18.5061 20.4004 18.0233 20.7513 17.6101 20.5341L12.2621 17.7224C12.0981 17.6362 11.9021 17.6362 11.738 17.7224L6.39002 20.5341C5.97689 20.7513 5.49404 20.4004 5.57294 19.9404L6.59432 13.9853C6.62565 13.8026 6.56509 13.6162 6.43236 13.4869L2.10573 9.26945C1.7715 8.94366 1.95594 8.37604 2.41783 8.30892L8.39708 7.44008C8.5805 7.41343 8.73906 7.29823 8.82109 7.13203L11.4951 1.7139Z"
    stroke="white"
    strokeWidth="2"
    strokeLinejoin="round"
  />

</svg>
</svg>


  );
}

//---------------------


export function BottomMenu( {onClick, curCode} )
{
  console.log('BOTTOM M.. ', onClick);
    return (

<div className="bottom-menu">

      <div style={{justifyContent: 'space-between', display: 'flex', width: '100%'}} >

    <BottomMenuItem title='Основное' ic={<IcHome />} onClick={onClick} code='baza'       isCur={ curCode==='baza' } />
    <BottomMenuItem title='История'  ic={<IcClock />} onClick={onClick} code='hist_train' isCur={curCode==='hist_train' } />
    <BottomMenuItem title='Покупки'  ic={<IcBuy />} onClick={onClick} code='hist_buy'   isCur={curCode==='hist_buy' } />
    <BottomMenuItem title='Записи'   ic={<IcCalen />} onClick={onClick} code='sched'      isCur={curCode==='sched' } />
    <BottomMenuItem title='Акции'    ic={<IcStar />} onClick={onClick} code='prom'      isCur={curCode==='prom' } />
    </div>
</div>


    );
    
}


