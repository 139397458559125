 


import "./LongButton.css"



export function LongButton( {tit, name, onClick} )
{
    function cb_clk()
    {
        console.log('BTN CLICK... ', name);
        onClick( name );
    }

    return (

        <div className="long-button" onClick={cb_clk}>
  <div className="long-button__tt">{tit}</div>
</div>


    );
}