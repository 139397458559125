import React from 'react';

import { OvrBusy }     from '../gui_com/ovr_busy';
import { ApiReq }      from '../api';
import { LongButton }  from "./LongButton";

export function SubSchedClubs( {onClick} )
{
    //console.log('sub sched clubs.. ', onClick);

    const [ready, setReady] = React.useState( false );




    return (

        <div style={{position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

        <div style={{gap: '20px', height: '100%', width: '320px', padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

            <LongButton tit='Архивная 11'  name={1} onClick={onClick} />
            <LongButton tit='Патриотов 34' name={2} onClick={onClick} />
        </div>


        <OvrBusy active={!ready} />

        </div>

    );
}
