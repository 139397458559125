  

import { format, parse, isValid, startOfWeek, startOfMonth, setDefaultOptions, addMonths, addDays, setHours, setMinutes, setSeconds }  from 'date-fns';

import { ru } from 'date-fns/locale'

import { getUnixTime } from 'date-fns'

setDefaultOptions({ locale: ru })

//--------- 

export function UnixTime( dt )
{
    return getUnixTime( dt );
}


export function DateMonthIndex(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return parseInt( format(dt, 'MM') );
}


export function DateAddDays(dt, num)
{
	return addDays(dt, num);
}


export function DateStartOfWeek(da)
{
	return startOfWeek(da, { weekStartsOn: 1 });
}


export function DateSetHMS(dt,h,m,s)
{
    dt = setHours(dt,h);
    dt = setMinutes(dt,m);
    dt = setSeconds(dt,s);
    
    return dt;
}

export function DateAddMonths(dt, num)
{
	return addMonths(dt, num);
}


export function DateStartOfMonth(dt)
{
	return startOfMonth(dt);
}


//------------------ PARSE ------------------------------


export function DateTimeParseIso(str)
{
	const r = parse(str, 'yyyy-MM-dd HH:mm:ss', new Date());
	if (isValid(r)) return r;
	return null;
}

export function DateParseIso(str)
{
    if (str === null) return null;
	const r = parse(str, 'yyyy-MM-dd', new Date());
	if (isValid(r)) return r;
	return null;
}


//----------------------------- FMT ---------------------

// ----->  чт 22.10
export function DateTimeFmtShort1(dt)
{
    return format(dt, 'EEEEEE dd.MM.yy');
}

export function DateTimeFmtHour(dt)
{
    return format(dt, 'HH:mm');
}

export function DateFmtLocHum(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'dd.MM.yy');
}


export function DateFmtDayNum(dt)
{
	//console.log('DateStrLoc ', dt);
	if (dt === null) return null;
	return format(dt, 'dd');
}

